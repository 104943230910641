<template>
  <v-expansion-panels
    v-model="activeItem"
    class="expansion-panel"
    tile accordion>
    <v-expansion-panel
      v-for="(item, i) in faQuestionList"
      :key="i"
    >
      <v-expansion-panel-header
        :class="{ 'sub-title': i === activeItem }"
        hide-actions
        class="question__content"
        v-if="item === 'My Strengths' || !!invitationResult.length"
      >
        <div class="expand-header">
          <span class="question__content_text">{{item}}
          </span>
          <img :src="minusIcon" alt="icon-minus" class="icon" v-if="i === activeItem"/>
          <img :src="plusIcon" alt="icon-plus" class="icon" v-else/>
        </div>
        <hr class="horizontal-line"/>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        class="question__answer"
      >
        <NewBarChart
          v-if="item === 'My Strengths'"
          :strength-conversion-score-for-other="strengthConversionScoreForOther"
          :strength-conversion-score-object="strengthConversionScoreObject"
          :respondents-count="respondentsCount"
        ></NewBarChart>
        <v-expansion-panels
          v-model="activeNestedItem"
          class="expansion-panel-nested"
          tile accordion
          v-if="item === 'Crowd Assesments'"
        >
          <v-expansion-panel
            v-for="(itemInvitation, j) in invitationResult"
            :key="j"
          >
            <v-expansion-panel-header
              :class="{ 'sub-title': j === activeNestedItem }"
              hide-actions
              class="question__content"
            >
              <div class="expand-header">
                <span class="question__content">{{itemInvitation.username}}
                <span class="count-respondents">
                  {{itemInvitation.count}}
                </span>
                </span>
                <img :src="minusIcon" alt="icon-minus" class="icon" v-if="j === activeNestedItem"/>
                <img :src="plusIcon" alt="icon-plus" class="icon" v-else/>
              </div>
              <hr class="horizontal-line"/>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="question__answer nested-expand"
            >
              <NewBarChart
                :strength-conversion-score-object="itemInvitation.strengthConversionU1"
                :strength-conversion-score-for-other="itemInvitation.strengthConversionU2"
                :respondents-count="itemInvitation.count"
                :show-switch-buttons="false"
              ></NewBarChart>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import helpFunction from '@helpers/helpFuction';
import minusIcon from '@assets/minus-icon.svg';
import plusIcon from '@assets/plus-icon.svg';
import NewBarChart from '@components/Charts/NewBarChart.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ExpandQuestionResult',
  components: {
    NewBarChart,
  },
  data: () => ({
    strengthConversionScoreObject: {},
    strengthConversionScoreForOther: {},
    listRes: false,
    activeItem: null,
    activeNestedItem: null,
    minusIcon,
    plusIcon,
    faQuestionList: ['My Strengths', 'Crowd Assesments'],
    respondentsCount: 0,
    invitationResult: [],
  }),
  created() {
    this.fetchPersonalityTypeReport();
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
    isAuthUser() {
      return this.getProfile.token;
    },
  },
  methods: {
    fetchPersonalityTypeReport() {
      if (!this.isAuthUser) {
        const uniqueId = localStorage.getItem('uniqueId');

        this.$api.personalityTypeReport.fetchPersonalityTypeReportNewFreeVersion(uniqueId)
          .then((res) => {
            this.strengthConversionScoreObject = res.strengthConversionScore;
            this.respondentsCount = res.othersAmount;
            // this.setRadar(res.selfResult.mainResult.split(/(?=[-+])/), 'Me');
            // this.showFeedBackModalByParams(res.othersAmount);

            this.myResultsScoreData = res.selfResult;
            this.SelfCoordinate = helpFunction.Coordinates(res.selfResult.mainResult);

            // this.setYouAnswerCard(this.SelfCoordinate[2]);

            // this.chartOptionsBar();
          }).catch(() => {
            this.$store.dispatch('showComponent/gotToStepInitial');
          });
      } else {
        this.$api.personalityTypeReport.fetchPersonalityTypeReport().then((res) => {
          this.data = [];
          this.strengthConversionScoreObject = res.strengthConversionScore;
          this.strengthConversionScoreForOther = res.strengthConversionScoreForOther;
          this.respondentsCount = res.othersAmount;
          // this.setRadar(res.selfResult.mainResult.split(/(?=[-+])/), 'Me');
          this.myResultsScoreData = res.selfResult;
          this.other = res.selfResult;
          this.otherLastResult = res;
          this.invitationResult = res.invitations;
          // this.showFeedBackModalByParams(res.othersAmount);

          this.SelfCoordinate = helpFunction.Coordinates(res.selfResult.mainResult);

          // this.setYouAnswerCard(this.SelfCoordinate[2]);
          this.groupOptions = res.groupForInLink;
          this.shareLink = `${window.location.protocol}//${window.location.host}${res.invitationLink}`;
          this.$emit('share-link', `${window.location.protocol}//${window.location.host}${res.invitationLink}`);
          this.$emit('respondents-count', res.othersAmount);
          // this.chartOptionsBar();
          // this.otherAmountCalculate(this.otherLastResult, this.selectedGroup);
        }).catch(() => {
          this.$store.dispatch('showComponent/gotToStepInitial');
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .self_answer {
    .expand-header {
      padding-top: 24px;
      padding-left: 25px;
      font-weight: bold;
      font-size: 20px;
      color: #320959;
    }

    .question__content {
      display: block;
    }
    .question__content_text {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .horizontal-line {
      margin-bottom: 0;
    }

    .icon {
      margin-right: 25px;
    }
  }
  .expansion-panel {
    .v-expansion-panel-content__wrap {
      padding: 0 !important;
    }
  }
  .count-respondents {
    background-color: #FF5151;
    border-radius: 50%;
    padding: 5px 10px;
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    color: #FFFFFF;
    margin-left: 15px;
  }
</style>
<style lang="scss">
  .expansion-panel {
    .v-expansion-panel-content__wrap {
      padding: 0 !important;
      border-bottom: 2px solid #e5e5e5;

    }
  }
</style>
