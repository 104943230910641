<template>
  <Content>
    <DeleteUserSecondary
      :dialogConfirm="secondaryDialog"
    />
    <div class="self_answer">
      <div class="сongratulation-completed-survey">
        <div class="title-crowd">{{getProfile.token ?
          'Complete your Strengths Review!' : 'Amazing!'}}</div>
        <div class="content-text">
          <span v-if="getProfile.token && !isOthersAmount">You need to get at least 4
            people to assess you. Ask your contacts and share the link below</span>
          <span v-else-if="getProfile.token && isOthersAmount">You are almost there.
            Share with your friends and family to complete your results</span>
          <span v-else>Share with your friends and family to complete your results</span>
        </div>
      </div>
      <div class="questionnaire-results">
        <ExpandQuestionResult
          @respondents-count="countRespondents"
          @share-link="saveLink"/>
      </div>
    </div>
    <div v-if="getProfile.token" class="space-class"></div>
    <div v-else class="space-class-second"></div>
    <div class="bottom-part-of-site">
      <div class="footer-menu-question" v-if="getProfile.token">
        <span class="logout" @click="logOut">Logout</span>
        <span class="delete"><DeleteUser
        @open-secondary-delete-modal="openSecondaryDeleteModal"/></span>
      </div>
      <button class="redirect-button" @click="register" v-if="!getProfile.token">
        Next</button>
      <button class="redirect-button"
              :style="{
                   background: linkCopied ? '#FF5151': '',
                   border: linkCopied ? '1px solid #FF5151': ''}"
              @click="copyInputData" v-else>{{buttonCopyLinkText}}</button>
    </div>
  </Content>
</template>

<script>
import isFreeVersion from '@helpers/func';
import helpFunction from '@helpers/helpFuction';
import { mapGetters } from 'vuex';
import minusIcon from '@assets/minus-icon.svg';
import plusIcon from '@assets/plus-icon.svg';
import ExpandQuestionResult from '@components/QuestionResult/ExpandQuestionResult.vue';
import copy from 'copy-to-clipboard';
import DeleteUser from '@views/DeleteUser.vue';
import userBehave from '@constants/userBehave';
import DeleteUserSecondary from '@views/DeleteUserSecondary.vue';


export default {
  components: {
    DeleteUserSecondary,
    DeleteUser,
    ExpandQuestionResult,
  },
  name: 'Report',
  data: () => ({
    strengthConversionScoreObject: {},
    strengthConversionScoreForOther: {},
    listRes: false,
    activeItem: false,
    minusIcon,
    plusIcon,
    heightQuestionnaireResult: 0,
    link: '',
    buttonCopyLinkText: 'Copy Link to Share',
    linkCopied: false,
    isOthersAmount: false,
    secondaryDialog: false,
  }),
  watch: {
    selectedOptions(value) {
      this.otherAmountCalculate(this.otherLastResult, value);
    },
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
    showButtons() {
      return (this.getProfile.registeredFromCSV && this.showButtonAskContactsForInvitation)
        || !this.getProfile.registeredFromCSV;
    },
    userRegisteredFromCSV() {
      return this.getProfile.registeredFromCSV;
    },
    isFreeVersionWebSiteWithCheck() {
      if (this.getProfile.completedQuestionnaires) {
        return isFreeVersion() && !this.getProfile.completedQuestionnaires
          .includes(process.env.QUESTIONNAIRE_ID);
      }
      return isFreeVersion();
    },
    isFreeVersionWebSite() {
      return isFreeVersion();
    },
  },
  methods: {
    openSecondaryDeleteModal() {
      this.secondaryDialog = true;
    },
    countRespondents(value) {
      this.isOthersAmount = value > 4;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('main');
    },
    register() {
      this.$api.userBehaveSite.saveSiteEvent(userBehave.redirectToRegisterPage);
      this.$router.push({
        name: 'registration',
      });
    },
    copyInputData() {
      this.$api.userBehaveSite.saveSiteEvent(userBehave.copyLink);
      const resultCopyText = `${this.link}`;
      copy(this.changeName(resultCopyText));
      this.buttonCopyLinkText = 'Link Copied to Clipboard';
      this.linkCopied = true;
      setTimeout(() => {
        this.buttonCopyLinkText = 'Copy Link to Share';
        this.linkCopied = false;
      }, 7000);
    },
    saveLink(link) {
      this.link = link;
    },
    openReasonWindowModalMethod(event) {
      this.openReasonWindowModal = event;
      this.openConsequencesWindowModal = false;
    },
    setChosenGroupCharacteristic(event, myResultsSc, othersResultsSc, chooseOtherResult) {
      helpFunction.setChosenGroupCharacteristic(event, myResultsSc, othersResultsSc,
        chooseOtherResult);
    },
    showButtonAskContactsForInvitation1() {
      this.showButtonAskContactsForInvitation = true;
      this.$forceUpdate();
    },
    redirectToQuestionnaireManagement() {
      this.$router.push('questionnaire-management');
    },
    openConsequencesWindowModalMethod() {
      this.openConsequencesWindowModal = true;
    },
    closeReasonWindowModalMethod() {
      this.openConsequencesWindowModal = false;
      this.openReasonWindowModal = false;
    },
    changeName(text) {
      return text.replace(/\{\{firstName\}\}/g, this.getProfile.name).replace(/\{\{secondName\}\}/g,
        this.getProfile.lastName);
    },
    setChosenCharacteristic(event) {
      this.selectedCharateristic = {
        name: event[2],
        text: event[3],
      };
    },
    setShowReportModal(value) {
      this.showReportModal = value;
    },
    openListResult() {
      this.listRes = !this.listRes;
    },
  },
};
</script>

<style lang="scss" scoped>
.show-res {
  font-size: 50px;
  color: #a158d9;
  caret-color: #ffffff;
}
.show-res-list {
  font-size: 50px;
  color: black;
  caret-color: black;
  padding-right: 5px;
}
.button_expand {
  background-color: #ffffff;
  color: #a158d9;
  border-radius: 40px;
}
.v-expansion-panel-header {
  width: 100%;
  border-radius: 12px;
  padding: 13px;
}
.sub-title {
  font-size: 35px;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.no_crowd {
  height: 32px;
  background-color: white;
  width: 55px;
  border-radius: 10px;
  font-size: 18px;
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.v-application .flex-row-reverse {
  flex-direction: row !important;
}
.scoresystem{
  background: #D7B8EF;
  border-radius: 20px;
  padding: 13px 0px;
}
.scorename{
    height: 5px;
  width: 100px;
  border-radius: 10px;
  font-size: 18px;
  font-family: "Montserrat";
  justify-content: left;
  align-items: center;
}
.scorepercentage{
  height: 15px;
   background-color: white;
  width: 85px;
  border-radius: 10px;
  font-size: 12px;
  font-family: "Montserrat";
   display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
  .v-expansion-panel:before {
    box-shadow: none;
    padding: 0%;
    background: white;
  }
  .scorecontent{
    background: #F2E7FA;
    border-radius: 20px;
    font-size: 14px;
    font-family: "Montserrat";
    text-align: left;
    margin-left: 50px;
  }
.redirect-button {
  font-weight: bold;
  font-size: 20px;
  color: #FFFFFF;
  box-sizing: content-box;
  width: 100%;
  background: $txtColor7;
  border: 1px solid $txtColor7;
  height: 102px;
  /*position: fixed;*/
  /*bottom: 0;*/
  /*max-width: 480px;*/
}

.self_answer{
  font-family: $newDefaultFont;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .expansion-panel {
    margin-bottom: 1px;
    border-radius: 30px 30px 0 0;
  }
  .title-crowd {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #320959;
    margin-top: 32px;
    margin-bottom: 11px;
  }
  .content-text {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #222222;
    margin: 10px 20px 30px;
  }
  .сongratulation-completed-survey {
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
  }
  .vertical-line {
    background: #686F9B;
    opacity: 0.1;
    border-radius: 4px;
    border: 3px solid #686F9B;
    width: 50px;
  }
  .questionnaire-results {
    height: 0;
    background-color: white;
    border-radius: 30px 30px 0 0;
    min-height: 150px;
    overflow: auto;
    flex-grow: 1;
  }
  .expand-header {
    padding-top: 24px;
    padding-left: 25px;
    font-weight: bold;
    font-size: 20px;
    color: #320959;
  }
}
@media (max-height: 700px) {
  .footer-menu-question {
    margin-bottom: 0 !important;
  }
  .space-class {
    height: 138px !important;
  }
}
.footer-menu-question {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-right: 25px;
  padding-left: 25px;
  background-color: white;
  margin-bottom: 20px;
  align-items: center;
  .logout {
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #7811C9;
    border-radius: 1px;
    cursor: pointer;
  }
  .delete {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FF5151;
    cursor: pointer;
  }
}
.horizontal-line {
  background: #686F9B;
  border: 1px solid #686F9B;
  opacity: 0.1;
  border-radius: 4px;
  width: 37px;
  height: 4px;
  display: block;
  margin: auto;
}
  .bottom-part-of-site {
    background-color: white;
    position: fixed;
    bottom: 0;
    max-width: 480px;
    width: 100%;
  }
  .space-class {
    height: 155px;
  }
  .space-class-second {
    height: 102px;
  }
@media (max-height: 820px) {
  .title-crowd {
    margin-top: 0 !important;
  }
}
</style>
