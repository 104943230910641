<template>
  <div class="registration">
    <Content class="registration-content">
      <Header/>
      <PasswordMismatchModal :showing-modal="openPopupModalMismatchPassword"
                             @show-modal="closeModal"/>
      <div class="registration-title">
        For you to access your data in future and for us to protect it, we need some details.
      </div>
      <div class="registration-text">
        We will never contact you and you can completely remove this data at anytime
      </div>
      <div class="registration-form">
        <form class="form">
          <BaseInput
            class="form-group"
            id="name"
            placeholder="Name"
            v-model="formData.name"
            :hasError="$v.formData.name.$error"
          >
            <template slot="error-message">
              <span v-if="!$v.formData.name.required">Field is required</span>
            </template>
          </BaseInput>
          <BaseInput
            class="form-group"
            id="email"
            placeholder="Email"
            v-model="formData.youEmail"
            :hasError="$v.formData.youEmail.$error"
          >
            <template slot="error-message">
              <span v-if="!$v.formData.youEmail.required">Field is required</span>
              <span v-if="!$v.formData.youEmail.mustBeCool && $v.formData.youEmail.required">
                Enter valid email address</span>
            </template>
          </BaseInput>
          <BaseInput
            :is-password="true"
            class="form-group"
            id="password"
            placeholder="Password"
            type="password"
            v-model="formData.password"
            :hasError="$v.formData.password.$error"
          >
          </BaseInput>
          <BaseInput
            :is-password="true"
            class="form-group"
            id="confirm_pass"
            placeholder="Confirm Password"
            type="password"
            v-model="formData.confirmPassword"
            :hasError="$v.formData.confirmPassword.$error"
          >
          </BaseInput>
          <div class="google-text-recaptcha checkbox-register">
            <label class="checkbox-agree" :class="{'error--text': $v.checkboxAgree.$error
             && !$v.checkboxAgree.sameAs}">
              <input type="checkbox" v-model="checkboxAgree">
              <span class="pseudocheckbox checkbox-inform"></span>
              <span>I agree to terms & conditions</span>
            </label>
            <label class="checkbox-inform">
              <input type="checkbox" v-model="checkboxInform">
              <span class="pseudocheckbox checkbox-term_conditons"></span>
              <span>Keep me updated when my results are ready</span>
            </label>
          </div>
        </form>
        <div class="twice-button-register">
          <button
            @click.prevent="start"
            id="registration_send_code"
            class="button button_register-default
          button_size-m slide-details__button button_disabled button-register">
            Register
          </button>
          <button
            @click="regret"
            id="registration_send_code"
            class="button button_register_regret-default
          button_size-m slide-details__button button_disabled cancel-button-registration">
            No Thanks
          </button>
        </div>
        <TermsConditionsModal />
        <PolicyModal />
        <div class="footer-text">
          <p>Do you already have an account?
            <span class="term_conditons" @click="openLoginForm">Login</span>
          </p>
        </div>
      </div>
    </Content>
    <ReCaptchaModal
      v-if="isFreeV"
      :show-window-modal="showModalStrange"
      :update-recaptcha="captchaMethod"
    />
  </div>
</template>

<script>

import configEnv from '@configEnv';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import PolicyModal from '@components/Modals/PolicyModal.vue';
import TermsConditionsModal from '@components/Modals/TermsConditionsModal.vue';
import fingerPrintBrowser from '@helpers/differentsServices';
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import ReCaptchaModal from '@components/Modals/ReCaptchaModal.vue';
import isFreeVersion from '@helpers/func';
import Header from '@components/Header/Header.vue';
import PasswordMismatchModal from '@components/Modals/PasswordMismatchModal.vue';
import userBehave from '@constants/userBehave';

Vue.use(VueReCaptcha, { siteKey: '6LcSoCEbAAAAAE8oA3ASZIJqEA0biiN3bTY8kmAc' });

// numeric, minValue, maxValue,
const {
  required,
  sameAs,
} = require('vuelidate/lib/validators');

const mustBeCool = (emailValid) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(emailValid).toLowerCase());
};

// const mustBeCodeTheSameCountry
// = (phone, objectResult) => phone.startsWith(`+${objectResult.diaCode}`);

export default {
  name: 'RegistrationPage',
  components: {
    PasswordMismatchModal,
    Header,
    PolicyModal,
    TermsConditionsModal,
    ReCaptchaModal,
  },
  props: {
    afterCompleteQuiz: {
      type: Boolean,
    },
  },
  data: () => ({
    openPopupModalMismatchPassword: false,
    checkboxAgree: false,
    checkboxInform: false,
    showModalStrange: false,
    configEnv,
    googleCaptcha: false,
    disableSendCode: false,
    diaCode: '',
    updatedTimeOut: null,
    formData: {
      // phone: '',
      name: '',
      surname: '',
      youEmail: '',
      department: '',
      role: '',
      managerEmail: '',
      month: undefined,
      day: undefined,
      year: undefined,
      diaCode: '',
      isoCountryCode: '',
      closeRegisterForm: true,
      password: '',
      confirmPassword: '',

    },
  }),
  validations: {
    formData: {
      name: {
        required,
      },
      youEmail: {
        required,
        mustBeCool,
      },
      password: {
        required,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
      // phone: {
      //   required,
      // },
    },
    checkboxAgree: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  watch: {
    // formData: {
    //   // eslint-disable-next-line no-unused-vars
    //   handler() {
    //     if (this.formData.phone.length > 5) {
    //       this.updatePhoneData();
    //     }
    //   },
    //   deep: true,
    // },
  },
  mixins: [validationMixin],
  computed: {
    isFreeV() {
      return isFreeVersion();
    },
    ...mapGetters({
      getPersonalityTest: 'invitation/getPersonalityTest',
      getProfile: 'auth/getProfile',
    }),
    captchaMethod() {
      return {
        captchaUpdate: this.captchaUpdate,
      };
    },
    getClassByLengthCountryCode() {
      return `code-length-${this.formData.diaCode.length}`;
    },
    getCountryCode() {
      if (this.getProfile.phone) {
        return this.getProfile.isoCountryCode;
      }
      return configEnv.onboarding.defaultStatePhone;
    },
  },
  async mounted() {
    this.disableSendCode = false;

    if (this.getProfile.phone) {
      this.formData.name = this.getProfile.name;
      this.formData.surname = this.getProfile.lastName;
      this.formData.youEmail = this.getProfile.email;
      this.formData.phone = this.getProfile.phone.replace(this.getProfile.codeCountry, '');
    }
    this.captchaUpdate();
  },
  methods: {
    closeModal(value) {
      this.openPopupModalMismatchPassword = value;
    },
    openLoginForm() {
      this.$router.push('login');
    },
    regret() {
      this.$router.push('questionnaire');
      // this.$store.commit('showComponent/setShowRegisterForm', false);
    },
    updatePhoneData() {
      clearTimeout(this.updatedTimeOut);
      this.updatedTimeOut = setTimeout(() => {
        if (this.formData.phone && this.formData.diaCode) {
          this.formData.phone = this.formData.phone.replace(`+${this.formData.diaCode}`, '');
        }
      }, 50);
    },
    async captchaUpdate() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      this.$api.auth.checkBotGoogleCaptcha(token).then((result) => {
        this.googleCaptcha = result;
        this.showModalStrange = !result;
        this.$emit('show-modal-strange', !result);
      });
    },
    countryChanged(data) {
      this.formData.isoCountryCode = data.iso2;
      this.formData.diaCode = data.dialCode;
    },
    async prepareDataForRequest() {
      // const formPhone = this.formData.phone;
      // const phone = `+${this.formData.diaCode}${
      //   formPhone.charAt(0) === "0" ? formPhone.substring(1) : formPhone
      // }`.replace(/\s/g, "");

      // let uniqueId = null;
      // if (localStorage.getItem('uniqueId') !== null) {
      //   uniqueId = localStorage.getItem('uniqueId');
      // }

      return {
        name: this.formData.name,
        surname: this.formData.surname,
        youEmail: this.formData.youEmail.toLowerCase(),
        checkboxInform: this.checkboxInform,
        // phone,
        password: this.formData.password,
        questionId: process.env.QUESTIONNAIRE_ID,
        // codeCountry: `+${this.formData.diaCode}`,
        // isoCountryCode: this.formData.isoCountryCode,
        uniqueId: localStorage.getItem('uniqueId'),
        fingerPrint: fingerPrintBrowser.getClientData(),
        googleCaptcha: this.googleCaptcha,
      };
    },
    async start() {
      this.$v.$touch();
      if ((this.formData.password !== '' && this.formData.confirmPassword !== '')
        && !this.$v.formData.confirmPassword.sameAsPassword) {
        this.openPopupModalMismatchPassword = true;
      }
      if (!this.$v.$invalid && this.googleCaptcha) {
        this.$emit('loading', true);
        try {
          const data = await this.prepareDataForRequest();
          await this.$store.dispatch('auth/registerRequest', data);
          localStorage.removeItem('uniqueId');
          await this.$api.userBehaveSite.saveSiteEvent(userBehave.registrationOnSite);
          this.$emit('loading', false);
          this.$router.push('questionnaire');
          this.$store.dispatch('showComponent/gotToStep4');
        } catch (error) {
          this.$emit('loading', false);
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .registration .block {
    border: 1px solid #ccc;
    padding: 6px 7px 6px 7px;
    margin: 0 0 24px 0;
  }
  .onBoarding__title{
    margin-bottom: 1vh;
    font-size: 3.5vh;
    line-height: 5vh;
    text-align: center;
  }
  .onBoarding__sub-title{
    margin-bottom: 3vh;
  }
  .onBoarding-carousel-button__caption{
    margin-top: 3vh;
  }
  .onBoarding__text{
    text-align: center;
    margin-bottom: 4vh;
    font-size: 2.25vh;
    line-height: 3vh;
  }
  .onBoarding-carousel__button{
    margin-bottom: 4vh;
  @media (max-height: $xxsMaxHeight) {
    margin-bottom: 2vh;
  }
  }

  .onBoarding-carousel{
    display: flex;
    flex-direction: column;
    margin: 0 -24px;
    height: 100%; //150px 17 calc(100vh - 12.5vh)
  /*min-height: 490px;*/
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div,
  .onBoarding-carousel__slide {
  }
  .onBoarding-carousel__slide{
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }
    .slick-slide{
      padding: 0 24px 24px;
      outline: none !important;
      @media (max-height: $xsMaxHeight) {
        padding-bottom: 20px;
      }
    }
    .slick-dots{
      /*bottom: -15px;*/
      position: static;
    }
    .slick-dots li button:before{
      font-size: 12px;
      color: $mnColor3;
      opacity: 1;
    }
    .slick-dots li.slick-active button:before{
      color: $mnColor2
    }
  }
  .onBoarding{
    background-color: $bgColor1;
    padding-bottom: 0;
    min-height: 100vh;
    .content{
      min-height: 100vh;
    }
  }
  .onBoarding__video{
    margin: 10px auto 3.75vh;
    display: block;
  }
  .onBoarding__video_step1{
    height: 45vh;
  }
  .onBoarding__video_step2{
    height: 28vh;
    @media (max-height: $xsMaxHeight) {
      height: 24vh;
    }
  }
  .flex-default-gap {
    display: flex;
    gap: $md-gap;
    .form__input {
      min-width: 0
    }
  }
  .form__input_for_icon {
    position: relative;
    display: flex;

    svg {
      position: absolute;
      top: 35%;
      color: $mnColor2;
      right: 8px;
    }

  }
  .flex-column {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .slide-details__button{
    margin-top: auto;
  }
  .google-text-recaptcha {
    color: #9f9f9f;
  }
  .registration a {
    color: #00719f;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
  .footer-text {
    padding-top: 10px;
    margin-top: 30px;
    border-radius: 2px;
    text-align: left;
    font-family: $newDefaultFont;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    p {
      margin-top: 10px;
    }
  }
  .horizontal-line-register {
    background: #686F9B;
    opacity: 0.1;
    border-radius: 2px;
    width: 37px;
    height: 4px;
  }
  .button_register-default {
    background: #7811C9;
    border: 1px solid #7811C9;
    box-sizing: border-box;
    border-radius: 5px;
    width: 172px;
  }
  .button_register_regret-default {
    background-color: #F3F4F6;
    border: 1px solid #A7A7A7;
    box-sizing: border-box;
    border-radius: 5px;
    color: #A7A7A7;
    font-weight: bold;
    font-size: 20px;
    width: 172px;
  }
  .button_size-regret {
    font-size: 13px;
    letter-spacing: 0.5px;
    padding: 12px 15px;
    border-radius: 8px;
    float: right;
  }
  .button_size-m {
    margin-left: 0 !important;
    font-size: 20px;
    padding: 0;
    height: 60px;
  }
  input::-webkit-input-placeholder {
    color: $formInputColor;
  }
  .google-text-recaptcha {
    padding-top: 15px;
  }
  .google-text-recaptcha label {
    padding-left: 5px;
  }
  .term_conditons {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #7811C9;
    cursor: pointer;
    padding-top: 10px;
  }
  .checkbox-inform, .checkbox-agree {
    text-align: left;
    font-family: $newDefaultFont;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    color: #222222;
    display: flex;
    .v-input--selection-controls__input {
      position: relative;
      &::before {
        content: " ";
        width: 18px;
        height: 18px;
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        /*background-color: #FFCBCB;*/
      }
    }
  }
  .registration-form {
    margin-left: 25px;
    margin-right: 25px;
  }
  .registration-content {
    height: 100%;
  }
  .registration {
    height: 100%;
  }
  .registration-title {
    font-family: $newDefaultFont;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #222222;
    margin-top: 58px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .registration-text {
    margin-top: 15px;
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #320959;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 25px;
  }
  .checkbox-reg{
    background-color: #FFCBCB !important;
  }
  .twice-button-register {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
  }
  ::v-deep.v-input__slot {
    margin-bottom: 0 !important;
  }
  .v-input__slot {
    margin-bottom: 0px !important;
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  input[type=checkbox] { display: none; }

  .pseudocheckbox::before {
    content: "";
    display: flex;
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    border-radius: 50%;
    color: white;
    padding-left: 3px;
    margin-right: 10px;
  }

  .checkbox-inform::before {
    background-color: #FF5151;
    border: 2px solid #FF5151;
    padding-top: 3px;
    padding-left: 6px;
  }
  .checkbox-term_conditons::before {
    background-color: #686F9B;
    border: 2px solid #686F9B;
    padding-top: 3px;
    padding-left: 6px;
  }
  input[type=checkbox]:checked + .pseudocheckbox::before { content: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24' fill='white'><path d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'/></svg>"); }
  .cancel-button-registration {
    color: #A7A7A7;
    border-radius: 5px;
    border: 1px solid #A7A7A7;
    white-space: nowrap;
  }
</style>
