<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="480"
      content-class="dialog-password-mis-match"
    >
      <v-card>
        <v-card-title class="text-h5 dialog-password-mis-match-title">
          <v-spacer></v-spacer>
              <div>Password mismatch</div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text >
          <v-spacer/>
          <div class="dialog-password-mis-match-content">
            Passwords are not the same.<br/>
            Please check.
          </div>
          <v-spacer/>
        </v-card-text>
        <v-card-actions>
          <div class="dialog-password-mis-match-buttons">
            <button
              @click="confirmModal"
              class="button-comfirm-dialog button-color"
            >
              Continue
            </button>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name: 'PasswordMismatchModal',
  data: () => ({
    dialog: true,
  }),
  props: {
    showingModal: {
      type: Boolean,
    },
  },
  methods: {
    confirmModal() {
      this.$emit('show-modal', false);
    },
  },
  watch: {
    showingModal: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      },
    },
    dialog(value) {
      this.$emit('show-modal', value);
    },
  },
};
</script>
<style lang="scss">
  .dialog-password-mis-match {
    border-radius: 30px;
  }
</style>

<style lang="scss" scoped>
  .dialog-password-mis-match {
    max-height: 100% !important;
    margin: 0;
    overflow: hidden;
    font-family: $newDefaultFont;

  }
  .dialog-password-mis-match-title {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #320959;
  }
  .dialog-password-mis-match-content {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
    margin-top: 15px;
  }
  .dialog-password-mis-match-content {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
    margin-top: 15px;
  }
  .dialog-password-mis-match-buttons {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .button-comfirm-dialog {
    background: #7811C9;
    border: 1px solid #7811C9;
    border-radius: 5px;
    padding: 8px 55px 8px 55px;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
</style>
