<template>
<div class="question header-initial">
    <div class="content_header">
      <p
      v-if="showDetailHeader"
      class="header-intial__1">
        Get your initial assessment  and in 2 minutes
        and start your journey now ...
      </p>
    </div>
<!--    <NewQuestion
      v-if="showQuestions"
      :questions="questions"
    />-->
   <QuestionsAskedFriend
   v-if="closeToFriend"
    />
  <Content v-if="showQuestions">
    <template v-if="show.questions">
      <ProgressBar
        :value="currentStep"
        :maxValue="allStepCount"
      />
      <div class="text questions__text" v-if="getDataByStep" v-html="getDataByStep.text">
        {{ getDataByStep.text }}
      </div>
      <div class="questions-list" v-if="getDataByStep">
        <div class="questions-item"
             v-for="(item, index) in getDataByStep.answers"
             :key="index"
             :class="{'active' : 1+index === selectedAnswer}"
        >
          <div
            class="questions-item__content"
            :id="idForButton[index]"
            @click.prevent="selectAndNextStep(index+1)"
          >
            {{ item.text }}
            <img v-if="1+index === selectedAnswer"
                 class="questions-item__icon-checked"
                 :src="checkbox"
                 alt="checkbox">
          </div>
        </div>
      </div>
    </template>
    <div class="questions-attentions" v-if="show.attentions">
      <div class="text">
        One last thing before we share the results - we need to verify you are human!
      </div>
      <div class="text">
          <span @click="this.setRedirectAuth" >
            <!-- <span v-if="isFreeVersionWebSite">
              <router-link :to="{
                name: 'registration'
              }">
                Register
              </router-link>
            </span> -->
            <span>
              <router-link :to="{
            name: 'main',
            params:  { slide: 3 }
          }">
                Register
              </router-link>
            </span>
          </span>
        or
        <span @click="this.setRedirectAuth">
            <router-link to="/login" >
            Login
          </router-link>
          </span>
      </div>
    </div>
  </Content>
  <div class="questions">
    <BlockQuestions />

    <Content>
      <Login />
    </Content>
  </div>
</div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
// import NewQuestion from '@modules/Questions';
import BlockQuestions from '@views/BlockQuestions.vue';
import checkbox from '../assets/checkbox_fill.svg';
import Login from './Login.vue';
// import QuestionsAskedFriend from './QuestionsAskedFriend.vue';

export default {
  name: 'Questions',
  data: () => ({
    checkbox,
    questions: null,
    currentStep: 1,
    allStepCount: 1,
    selectedAnswer: null,
    formData: {},
    idForButton: {
      0: 'agree_strongly',
      1: 'agree_moderately',
      2: 'agree_little',
      3: 'disagree_little',
      4: 'disagree_moderately',
      5: 'disagree_strongly',
    },
    show: {
      questions: true,
      attentions: false,
    },
    redirectLink: 'invintation-report',
    loading: false,
  }),
  components: {
    // NewQuestion,
    // QuestionsAskedFriend,
    BlockQuestions,
    Login,
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      showQuestions: 'showComponent/showQuestions',
      showFreQuestions: 'showComponent/showFreQuestions',
      showRegisterForm: 'showComponent/showRegisterForm',
      showDetailHeader: 'showComponent/showDetailHeader',
      showResult: 'showComponent/showResult',
      closeToFriend: 'showComponent/closeToFriend',
    }),
    getDataByStep() {
      if (!this.questions) return null;

      if (this.currentStep === 1) {
        this.startColleaguesTest();
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.questions[this.currentStep - 1].text = this.questions[this.currentStep - 1].text
        .replace(/{{/, '<span class="highlighting-name">').replace(/}}/, '</span>');
      return this.questions[this.currentStep - 1];
    },
    isPersonalityTest() {
      return this.$route.meta.invitationType === process.env.QUESTIONNAIRE_ID;
    },
    isAuth() {
      return this.getProfile.token;
    },
    // isFreeVersionWebSite() {
    //   return isFreeVersion();
    // },
  },
  created() {
    this.fetchData();
    const url = window.location;
    const checkHref = url.href.search('invitation');
    if (checkHref > -1) {
      this.$store.commit('showComponent/setShowHeader1', true);
      // this.$store.commit('showComponent/setShowQuestions', false);
      this.$store.commit('showComponent/setShowDetailHeader', false);
    }
  },
  methods: {
    redirectToMainPage() {
      this.$router.push({ name: 'main' });
    },
    completedColleaguesTest() {
      this.$gtag.event('Click test', {
        [window.location.pathname.split('/').pop()]: 'Complete test', // eslint-disable-line
        'value': 1,                                                             // eslint-disable-line
      });
    },
    startColleaguesTest() {
      this.$gtag.event('Click test', {
        [window.location.pathname.split('/').pop()]: 'Start test', // eslint-disable-line
        'value': 1,                                                          // eslint-disable-line
      });
    },
    fetchData() {
      if (this.isPersonalityTest) {
        return this.fetchInvitationQuestionnaire(this.$route.params.id);
      }
      return this.fetchQuestions();
    },
    fetchQuestions() {
      this.$api.questionnaire.fetchQuestionnaire()
        .then((data) => {
          this.setResponseData(data);
        });
    },
    fetchInvitationQuestionnaire(id) {
      this.$api.questionnaire.fetchInvitationQuestionnaire(id)
        .then((data) => {
          this.setResponseData(data);
        });
    },
    setResponseData(data) {
      this.questions = data.questionList;
      this.setLengthStep(data.questionList);
    },
    setLengthStep(data) {
      this.allStepCount = data.length;
    },
    selectedAnswers(question) {
      this.selectedAnswer = question;
    },
    setStep(step) {
      this.currentStep = step;
    },
    setAnswer(questionId) {
      this.formData[this.getDataByStep.qid] = questionId;
    },
    async saveAnswerByPersonalityTest() {
      if (!localStorage.getItem('uniqueId')) {
        localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
          * Math.floor(Math.random() * Date.now()) * Math.random())}`);
      }

      let requestParam = '?option=';
      if (this.$route.query.option) {
        requestParam += this.$route.query.option;
      } else {
        requestParam = '';
      }

      return this.$store.dispatch('invitation/setPersonalityTest',
        {
          formData: this.formData,
          id: this.$route.params.id,
          uniqueId: localStorage.getItem('uniqueId'),
          requestParam,
        }).then(() => {
        if (this.isAuth) {
          return this.$router.push({ name: 'questionnaire-management' });
        }

        return this.toggleShowContent();
      }).catch(() => {
        if (this.isAuth) {
          return this.$router.push({ name: 'questionnaire-management' });
        }
        return this.$router.push({
          name: 'main',
        });
      });
    },
    setRedirectAuth() {
      this.$store.dispatch('auth/setRedirectAuth', 'questionnaire-management');
    },
    toggleShowContent() {
      this.show.questions = !this.show.questions;
      this.show.attentions = !this.show.attentions;
    },
    async saveAnswer() {
      this.completedColleaguesTest();
      if (this.isPersonalityTest) {
        return this.saveAnswerByPersonalityTest();
      }

      if (!this.isAuth) {
        if (!localStorage.getItem('uniqueId')) {
          localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
            * Math.floor(Math.random() * Date.now()) * Math.random())}`);
        }

        return this.$api.questionnaire.saveAnswerFreeVersionNew(this.formData, localStorage.getItem('uniqueId'))
          .then(() => {
            this.$router.push({ name: 'report' });
          }).catch(() => {
            this.$router.push({ name: 'report' });
          });
      }


      return this.$api.questionnaire.saveAnswer(this.formData)
        .then(() => {
          const profile = {
            ...this.getProfile,
            completedQuestionnaires: [...this.getProfile.completedQuestionnaires,
              process.env.QUESTIONNAIRE_ID],
          };

          this.$store.dispatch('auth/updateProfile', profile).then(() => {
            this.$router.replace(this.getRedirectAuth);
          });
          this.$router.push({ name: 'report' });
        });
    },
    // eslint-disable-next-line func-names
    nextStep: debounce(async function () {
      const nextStep = this.currentStep + 1;

      if (!this.selectedAnswer) return;

      this.setAnswer(this.selectedAnswer);
      this.selectedAnswer = null;

      if (nextStep <= this.allStepCount) {
        this.setStep(this.currentStep + 1);
      } else {
        this.loading = true;
        await this.saveAnswer();
        this.loading = false;
      }
    }, 700),
    selectAndNextStep(answer) {
      this.selectedAnswers(answer);
      this.nextStep();
    },
  },
};
</script>

<style lang="scss">
  .questions-attentions{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0 24px;
    transform: translate(-50%, -50%);
    .text{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .highlighting-name {
    text-transform: uppercase;
    color: red;
    font-weight: bold;
  }
  .questions {
    padding: 0 10px;
    .block {
      border: 1px solid #ccc;
      margin: 0 auto;
    }
  }
  .button_theme-default {
    background-color: #BC88E4;
  }
</style>
