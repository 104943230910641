export default [
  {
    key: 'Cur',
    name: 'Curiosity',
    content: `<strong>Curious</strong> individuals often have a strong desire to know, understand, or learn something.<br/>
<br/> As someone who scores highly on <strong>Curiosity</strong>, you are likely to experience the following, you: <br/>
<br/>
<ol>
    <li>tend to ask lots of questions and are yearning for a better understanding of the things around
        you.<br/><br/>
    </li>
    <li> are less likely to be bored as you often occupy your mind with various things that are interesting.
        <br/><br/></li>
    <li>often live in the moment and feel alive as you constantly think about things occurring currently.
        <br/><br/></li>
    <li>might be good at generating ideas as a result of making linkages between all the information you
        acquired.
    </li>
</ol>`,
  },
  {
    key: 'Lov',
    name: 'Love of Learning',
    content: `Individuals who score highly on <strong>Love of Learning</strong> have a passion for acquiring new knowledge, expriences, and skills.<br/>
<br/>Enjoying learning means you: <br/><br/>
<ol>
    <li>are likely to have a growth mindset - meaning that you believe that your skills/abilities could improve
        overtime with work and constant learning.<br/><br/></li>
    <li>tend to gain great satisfaction from mastering new skills and knowledge.<br/><br/></li>
    <li> tend to go out of your way to understand a certain topic/subject that interests you.<br/><br/></li>
    <li>often get excited during the process of learning new things.</li>
</ol>
    `,
  },
  {
    key: 'Jud',
    name: 'Judgment',
    content: `Scoring  highly on <strong>Judgment</strong> means you are likely to have an unbiased and uncritical outlook in life.
<br/><br/> As someone who scores highly on <strong>Judgment</strong>, you are likely to experience the following.
<br/> You:<br/><br/>
<ol>
    <li>tend to be someone with an open mind who looks at things from different perspectives before reaching a
        conclusion.<br/><br/></li>
    <li> are likely to be a good communicator and someone who listens intently as you are always open to hear different
        opinions and consider them fairly.<br/><br/></li>
    <li>are not likely to get offended when challenged by different voices, on the contrary, you tend to view them as
        food for thought that could inspire thinking.
    </li>
</ol>`,
  },
  {
    key: 'Ori',
    name: 'Creativity',
    content: `
    <strong>Creative</strong> individuals are the source of original and practical ideas.  People who score highly on <strong>Creativity</strong> are likely to experience the following.  You:<br/><br/>
<ol>
    <li>are good at coming up with novel solutions to problems and people come to you for these novel ideas and inspiration.<br/><br/></li>
    <li>often get excited when you think of new ideas.<br/><br/></li>
    <li>may display more artistic talent than people around you.<br/></li>
</ol>
  <strong>Creativity</strong> is practical; you provide original solutions and ideas that are also useful and applicable.
`,
  },
  {
    key: 'Soc',
    name: 'Emotional Intelligence',
    content: `
    <strong>Emotional Intelligent</strong> people are mostly self-aware and tend to be empathetic towards others.
<br/><br/>  As someone who scores highly on Emotional Intelligence, you are likely to experience the following.You:
<br/><br/>
<ol>
    <li>have superior social skills so are better at making connections with different people from different backgrounds
        than others.<br/><br/></li>
    <li>are good at responding to people's needs since you have a better understanding of other people's motives,
        emotions, and feelings than other people.<br/><br/></li>
    <li>are often flexible and versatile in social occasions, someone who can adapt to most scenarios.</li>
</ol>`,
  },
  {
    key: 'Per',
    name: 'Wisdom',
    content: `
    Those scoring highly on <strong>Wisdom</strong> tend to have a strong moral compass and humility towards others, and ability to deal with uncertainty.
<br/><br/>As someone who scores highly on <strong>Wisdom</strong>, you tend to:<br/><br/>
<ol>
    <li> have a wider breadth and depth of life experience and tend to apply this knowledge reflectively.<br/><br/></li>
    <li> be able to balance your self-interest with the interests of others, and are selfless when necessary.<br/><br/></li>
    <li> encompass other traits such as Creativity, Curiosity, Judgment and Perspective.<br/></li>
</ol>
Few people are resentful to receive<strong>Emotional Intelligence</strong> and this unique perspective often attracts people to you for advice when they are struggling in their own challenging situations.`,
  },
  {
    key: 'Val',
    name: 'Courage',
    content: `Scoring highly on
<strong>Courage</strong> suggests, in  the face of opposition,  the emotional strengths needed to will yourself to accomplish a goal may be more available to you than others.
<br/><br/> As someone who scores highly on <strong>Courage</strong>, you tend to: <br/><br/>
<ol>
    <li>rarely shy away from threat, challenge, difficulty, or pain; and you may speak up for what is right even if
        there is opposition to it.<br/><br/></li>
    <li>act upon your own convictions even if unpopular. Consequences of your actions usually seem manageable, as you
        have full belief in yourself.<br/><br/></li>
    <li>have a willingness to put yourself at risk if necessary, but this is by no means limited to physical
        bravery.<br/><br/></li>
</ol>`,
  },
  {
    key: 'Ind',
    name: 'Persistence',
    content: `
    A higher score on <strong>Persistence</strong> shows the ability and willingness to stick to tasks, despite how difficult they may be; giving up is not an option.<br/><br/>Persistent individuals are likely to:<br/><br/>
<ol>
    <li>have a strong sense of purpose and desire and know what they want to achieve in life.<br/><br/></li>
    <li>be good at regulating thoughts/actions that pull you away from achieving the goal.<br/><br/></li>
    <li>have a higher capacity to delay gratification and be determined to follow-through.<br/></li>
</ol>
In fact, <strong>Persistence</strong> in some form is almost essential to success.`,
  },
  {
    key: 'Int',
    name: 'Integrity',
    content: `
    Those who score high on <strong>Integrity</strong> are known for their authenticity, honesty and loyalty to those around them. Principles and morals are an important part of their identity. <br/><br/>As someone who scores highly on Integrity, you are likely to experience the following. You:<br/><br/>
<ol>
    <li>exhibit a regular pattern of behaviour that is consistent with your public values and so practice what you
        preach.<br/><br/></li>
    <li>are more likely to publicly justify your moral convictions, even if those opinions are not common or
        popular.<br/><br/></li>
    <li>treat others with care, helping those in need and are sensitive to the needs of others.<br/><br/></li>
    <li>have a stronger awareness of your own values and their importance to how you live your life.</li>
</ol>`,
  },
  {
    key: 'Kin',
    name: 'Kindness',
    content: `
    Individuals with a high
<strong>Kindness</strong> score are patient and always considering others whilst expecting nothing in return: their selflessness is rewarded in less obvious ways.
<br/><br/>Scoring highly on <strong>Kindness</strong> means you are likely to:<br/><br/>
<ol>
    <li>be a good team player and put the needs of others first.<br/><br/></li>
    <li>rarely think badly of other people's intentions and motives, giving them the benefit of the doubt.<br/><br/>
    </li>
    <li>spare no effort when it comes to helping others, even if that means postponing your own priorities. <br/></li>
</ol>
<strong>Kindness</strong> is generally considered a combination of empathy, sympathy, moral reasonsoning and social responsibility.`,
  },
  {
    key: 'Cap',
    name: 'Capacity for Love',
    content: "Having a high <strong>Capacity for Love</strong> shows an individual's caring nature and empathy for others.  Those with a high <strong>Capacity for Love</strong> are generally loyal and content.<br/><br/>Having a high <strong>Capacity for Love</strong>, you:<br/><br/><ol><li>always show appreciation and care towards people around you.<br/><br/></li><li>don't shy away from showing affection and kindness, the love you have means more to you than how others see you.<br/><br/></li><li>value interpersonal relationships as important in nurturing your life; for you, people are a big part of what you find meaningful in life.</li></ol>",
  },
  {
    key: 'Cit',
    name: 'Teamwork',
    content: `
    A person with a high
<strong>Teamwork</strong> score will tend to be a strong-minded leader-type with clear goals and the ability to communicate well with others.
<br/><br/>Scoring highly on <strong>Teamwork</strong> is likely to mean you: <br/><br/>
<ol>
    <li>tend to behave responsibly with an incentive to protect and improve a collective interest rather than your own
        benefit.<br/><br/></li>
    <li>are likely to be a good team player and communicate information well to those around you.<br/></li>
</ol>
Many basic character strengths, such as communication, self-control, and humility, support a person's ability to work as a team.`,
  },
  {
    key: 'Equ',
    name: 'Fairness',
    content: `Listening, balance and sharing are key attributes of those with a high
<strong>Fairness</strong> score. They accept consequences and tend to follow rules.<br/><br/>Being fair, you:<br/><br/>
<ol>
    <li>rarely jump to conclusions and often feel the need to take many things into account when judging a difficult
        situation.<br/><br/></li>
    <li>enjoy the process of trying to be fair to people despite it being hard on occasion.<br/><br/></li>
    <li>have the ability to listen and be open to different views and opinions.<br/></li>
</ol>
Fair people are good at putting aside their personal feelings when judging a situation involving others.`,
  },
  {
    key: 'Lea',
    name: 'Leadership',
    content: `
    <strong>Leadership</strong> is managing the process of organising, directing, coordinating, and motivating others in their efforts toward achieving certain group or organisational goals.
<br/>
<br/>Leaders are better at keeping up morale and relationships between the people they lead.  Good Leaders differentiate themselves by also inspiring the group.
<br/><br/>Having a high sense of <strong>Leadership</strong> you:<br/><br/>
<ol>
    <li>are confident in yourself and the decisions you make, and are willing to go a long way to prove that.<br/><br/></li>
    <li>can adapt your behaviour and skills to meet different peoples’ needs and situations.<br/><br/></li>
    <li>are focused, driven and don't shy away from risk taking.</li>
</ol>`,
  },
  {
    key: 'Sel',
    name: 'Self-control',
    content: `High scorers in
<strong>Self-control</strong>, when presented with a goal or target, tend to have the self-discipline to resist temptations that pull them away from goal achievement.
<br/><br/>Someone with a high <strong>Self–control</strong> score will:<br/><br/>
<ol>
    <li>have the ability to avoid negative behaviours that force them away from their goals.<br/><br/></li>
    <li>recognise and resist urges that don't benefit them in the long run.<br/><br/></li>
    <li>keep a level head and make sensible, logical decisions.<br/></li>
</ol>They feel a strong sense of responsibility themselves such that they tend to choose to do the right things rather than the comfortable things.`,
  },
  {
    key: 'Pru',
    name: 'Prudence',
    content: `Prudent individuals are careful and sensible. Someone who is prudent, is likely to be cautious with most decisions made rather than acting on impulse.  They are good at making thorough evaluations before deciding to act.
<br/><br/>With a high Prudence score, you are:<br/><br/>
<ol>
    <li>likely to avoid risky situations or making decisions not based on logic.<br/><br/></li>
    <li>level headed and able to see the bigger picture in most situations.<br/><br/></li>
    <li>a good listener and take into account all information given to you.<br/></li>
</ol>Prudent people are less likely to sacrifice long-term goals for short-term gratification using critical thinking and active open-mindedness.`,
  },
  {
    key: 'App',
    name: 'Appreciation of Beauty',
    content: `
    Individuals with an <strong>Appreciation of
    Beauty</strong>, meaning appreciating beauty, excellence or skilled performance, are likely to deeply value a wide range of things such as nature, art, mathematics, science and everyday experiences.
<br/><br/><strong>Appreciation</strong>  of Beauty means you:<br/><br/>
<ol>
    <li>are a free-spirited, open-minded person with many passions.<br/><br/></li>
    <li>are grateful for those around you and appreciate acts of kindness.<br/><br/></li>
    <li>can see the good in most people and things in life.<br/></li>
</ol>You may find you are able to empathise with the sacrifice required to produce things of beauty and want to spend time with others who recognise those sacrifices too.`,
  },
  {
    key: 'Gra',
    name: 'Gratitude',
    content: `A high score on
<strong>Gratitude</strong> shows a person's humility and kind-hearted nature. They appreciate loved ones and the surrounding world, despite obstacles along the way.
<br/><br/>A strong sense of Gratitude  means you are likely to:<br/><br/>
<ol>
    <li>find thankfulness and appreciating what you have is a core element in your life.<br/><br/></li>
    <li>gain satisfaction from expressing your gratitude.<br/><br/></li>
    <li>tend to appreciate things in life more than others, and not take things for granted.<br/></li>
</ol>Grateful people recognise the receipt of generosity warmly and often want to act on that initial generosity.`,
  },
  {
    key: 'Hop',
    name: 'Optimism',
    content: `<strong>Optimism</strong> is a mental attitude characterised by hope and confidence in success and a positive future. Optimists are those who expect good things to happen, where pessimists instead predict unfavourable outcomes.
<br/><br/>As someone who scores highly on <strong>Optimism</strong>, you are likely to:<br/><br/>
<ol>
    <li>see opportunities rather than problems when experiencing a crisis.<br/><br/></li>
    <li>feel satisfied with your life as you often believe good things are on their way.<br/><br/></li>
    <li>be good at bouncing back from setbacks as you believe things will get better.<br/><br/></li>
</ol>`,
  },
  {
    key: 'Spi',
    name: 'Spirituality',
    content: `<strong>Spirituality</strong>, or having coherent beliefs about the higher purpose and meaning of the universe, involves knowing where one fits within the greater scheme.
<br/>
<br/>Spiritual people have a sense of a relationship between themselves and the ‘divine’ and virtues resulting from that relationship.
<br/><br/>Being a person with a high score in Spirituality, you will:<br/><br/>
<ol>
    <li>have an open mind when it comes to listening to differences of opinion.<br/><br/></li>
    <li>not be stressed about mundane issues, being grateful for the life they have.<br/><br/></li>
    <li>be someone with a lot of love and positive energy to share with those around them.<br/></li>
</ol>
Having beliefs about the meaning of life is likely to significantly shape your conduct and provide comfort to you.`,
  },
  {
    key: 'Mod',
    name: 'Modesty',
    content: `
    <strong>Modest</strong> individuals are free from pride and arrogance, they have a humble view of their own importance. They can find it hard to see the skills and qualities they may possess.
<br/><br/>As someone who scores highly on Modesty, you are likely to behave, feel and think in the following ways.  You:
<br/><br/>
<ol>
    <li>tend to applaud other people's success instead of feeling envy or self-pity.<br><br/></li>
    <li>have an authentic outlook in life such that you are not vain or materialistic.<br/><br/></li>
    <li>rarely exaggerate your own achievement to get more attention.<br/><br/></li>
    <li>are less likely to be self-absorbed or egotistical.<br><br/></li>
    <li>are good at understanding that there is always room for improvement and that it is okay to take it step by step.<br/><br/>
    </li>
</ol>`,
  },
  {
    key: 'Hum',
    name: 'Humor',
    content: `
    Liking to laugh and tease, bringing smiles to others are characteristics of people scoring highly in
<strong>Humour</strong>.<br/><br/> Humorous people tend to:<br/><br/>
<ol>
    <li>see the lighter side of situations; this doesn't necessarily mean they are always telling jokes.<br/><br/></li>
    <li>relieve tension in situations and lift spirits in times of despair, providing aid to their peers.<br/><br/></li>
    <li>be playful which relieves boredom and brings people together.<br/></li>
</ol><strong>Humour</strong> as strength is often found in conjunction with <strong>Enthusiasm</strong>.`,
  },
  {
    key: 'Zes',
    name: 'Enthusiasm',
    content: `<strong>Enthusiastic</strong> characters are passionate, confident and driven. They aren't afraid to throw themselves into daunting situations whilst also remaining level-headed.
<br/><br/>As someone who scores highly on <strong>Enthusiasm</strong>, you are likely to:<br/><br/>
<ol>
    <li>embrace life with a strong and active manner.<br/><br/></li>
    <li>feel active and full of energy for whatever you are doing.<br/><br/></li>
    <li>experience people often describing you as someone with 'good vibes' or 'positive energy'.</li>
</ol>`,
  },
  {
    key: 'For',
    name: 'Forgiveness',
    content: `<strong>Forgiveness</strong> refers to a conscious, deliberate decision to release feelings of resentment or vengeance toward a person or group, regardless of whether they actually deserve forgiveness.
<br><br>As someone who scores highly on Forgiveness, you tend to react in the following ways.  You:<br/><br/>
<ol>
    <li>rarely hold a grudge against others that have wronged or offended you.<br/><br/></li>
    <li>are good at empathising with others and understanding why they might have done harmful things.<br/><br/></li>
    <li>often feel at peace with oneself and others.</li>
</ol>`,
  },
];
