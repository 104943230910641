<template>
  <div class="container">
    <div class="logo-innerworks">
      <span class="logo-block" @click="redirectToMainPage">
        <img
          src="../assets/small_logo.svg"
          alt="logo innerworks"
        />
        <span class="header_text header_text__1 logo-text">
          Personality Assessment
        </span>
      </span>
    </div>
    <div>
      <LoadingAllScreen :is-loading.sync="loading" :scroll-lock="false"/>
      <AccountNotFoundModal  :showing-modal="openPopupModal"
                             @show-modal="closeModal"/>
      <Content>
        <div class="login-content">
          <div class="text-login">
            <span class="text-login">Login</span>
          </div>
          <form class="form">
            <!-- <InputTel
          v-model="formData.phone"
          :diaCode="diaCode"
          :validPhone="$v.formData.phone"
          @onDiaCode="countryChanged"
          :placeHolder="configEnv.onboarding.placeholderPhone"
          :defaultCountry="configEnv.onboarding.defaultStatePhone"
        /> -->
            <BaseInput
              class="form-group"
              id="login_email"
              :placeholder="configEnv.onboarding.emailPlaceHolder"
              v-model="formData.mail"
              :hasError="$v.formData.mail.$error"
            >
              <template slot="error-message">
                <!--              <span v-if="!$v.formData.mail.required">
                  Field is required</span>-->
                <!--              <span v-else-if="!$v.formData.mail.mustBeCool">
                  Check correct email</span>-->
              </template>
            </BaseInput>
            <BaseInput
              class="form-group"
              placeholder="Password"
              id="login_password"
              v-model="formData.password"
              type="password"
              :isPassword="true"
              :hasError="$v.formData.password.$error"
            >
              <template slot="error-message">
                <!--              <span v-if="!$v.formData.password.required">
                  Field is required</span>-->
              </template>
            </BaseInput>
            <div v-if="showTextIncorrectText" class="login-error-message">
              <img :src="errorIcon" alt="error-icon" class="left"/>
              <span>Incorrect Password</span>
            </div>
            <div class="block-button">
              <button
                class="button button_disabled button-login"
                @click.prevent="getProfile.token ? logOut() : login()"
                id="log_in"
              >
                {{"Login"}}
              </button>
              <span
                @click="forgotPass"
                class="forgot-pass">Forgot password?</span>
            </div>
            <div class="form-group form-group_link forgot-password">
              <!-- <router-link to="/reset-password" class="link">
                Forgot password?</router-link> -->
              <!-- <router-link to="/confirm-user-details" class="link change-details-link">
            Change details?
          </router-link> -->
            </div>
          </form>
        </div>
      </Content>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import configEnv from '@configEnv';
import checkRole from '@helpers/adminFunction';
import differentsServices from '@helpers/differentsServices';
import errorIcon from '@assets/error-icon.svg';
import AccountNotFoundModal from '@components/Modals/AccountNotFoundModal.vue';

const { required } = require('vuelidate/lib/validators');

const mustBeCool = (emailValid) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(emailValid).toLowerCase());
};

export default {
  components: {
    AccountNotFoundModal,
  },
  mixins: [validationMixin],
  validations: {
    formData: {
      // phone: {
      //   required,
      // },
      mail: {
        required,
        mustBeCool,
      },
      password: {
        required,
      },
    },
  },
  data: () => ({
    errorIcon,
    configEnv,
    showInfoModalAboutGeolocation: false,
    showTextIncorrectText: false,
    formData: {
      // phone: null,
      mail: null,
      password: null,
    },
    updatedTimeOut: null,
    countUpdate: 1,
    diaCode: '',
    loading: false,
    openPopupModal: false,
  }),
  props: {
    resultPage: {
      type: Boolean,
      defaultValue: false,
    },
  },
  computed: {
    ...mapGetters({
      getRedirectAuth: 'auth/getRedirectAuth',
      getProfile: 'auth/getProfile',
      logIn: 'showComponent/logIn',
    }),
    getClassByLengthCountryCode() {
      return `code-length-${this.diaCode.length}`;
    },
  },
  watch: {
  },
  beforeCreate() {
    this.$store.dispatch('showComponent/showHeaderLogo');
  },
  methods: {
    closeModal(value) {
      this.openPopupModal = value;
    },
    forgotPass() {
      this.$router.push('questionnaire');
      this.$store.dispatch('showComponent/gotToStep7');
    },
    showLoginForm() {
      this.$router.push('login');
    },
    updatePhoneData() {
      clearTimeout(this.updatedTimeOut);
      this.updatedTimeOut = setTimeout(() => {
        if (this.formData.phone && this.diaCode) {
          this.formData.phone = this.formData.phone.replace(`+${this.diaCode}`, '');
        }
      }, 50);
    },
    prepareDataForRequest() {
      // const formPhone = this.formData.phone;
      // const phone = `+${this.diaCode}${
      //   formPhone.charAt(0) === '0' ? formPhone.substring(1) : formPhone
      // }`.replace(/\s/g, '');
      let uniqueId = null;
      if (localStorage.getItem('uniqueId') !== null) {
        uniqueId = localStorage.getItem('uniqueId');
      }

      return {
        password: this.formData.password,
        // phone,
        mail: this.formData.mail,
        uniqueId,
        questionId: process.env.QUESTIONNAIRE_ID,
        fingerPrintData: differentsServices.getClientData(),
      };
    },
    countryChanged(data) {
      this.diaCode = data.dialCode;
    },
    savePhoto(userWithoutPhoto, email, id) {
      if (userWithoutPhoto) {
        differentsServices.avatarPhotoService(email, id);
      }
    },
    async login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const data = await this.prepareDataForRequest();
        try {
          await this.$store.dispatch('auth/loginRequest', data);
          localStorage.removeItem('uniqueId');
          const {
            completedQuestionnaires, userWithoutPhoto, email, id,
          } = this.getProfile;

          // const timeModel = setTimeout(() => {
          //   this.showInfoModalAboutGeolocation = true;
          // }, 300);
          // const geolocation = await differentsServices.getGeolocation();
          // clearTimeout(timeModel);
          // const getGeoData = await differentsServices.requestSearchGeoPosition(geolocation);
          //
          // if (getGeoData.allowGetGeolocation) {
          //   this.$api.auth.updateGeoLocationUsers(getGeoData, true, id);
          // } else if (!getGeoData.allowGetGeolocation) {
          //   this.$api.auth.updateGeoLocationUsers(getGeoData, false, id);
          // }

          this.savePhoto(userWithoutPhoto, email, id);
          this.loading = false;

          if (checkRole.isAdmin()) {
            this.$router.push({
              name: 'adminMenu',
            });
          } else if (!completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
            this.$router.push('questionnaire');
            this.$store.dispatch('showComponent/gotToStepInitial');
          } else if (!checkRole.isAdmin()) {
            this.$router.push('questionnaire');
            this.$store.dispatch('showComponent/gotToStep4');
          } else {
            this.$router.replace(this.getRedirectAuth);
          }
        } catch (error) {
          this.loading = false;
          if (error.response.status === 404) {
            this.openPopupModal = true;
          } else {
            this.showTextIncorrectText = true;
          }
        }
      }
    },
    redirectToMainPage() {
      this.$router.push({ name: 'main' });
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$store.dispatch('showComponent/gotToStepInitial');
    },
  },
};

</script>
<style lang="scss" scoped>
  .container {
    height: 100%;
  }
</style>

<style lang="scss">
  button:focus {
    border: 2px solid #66afe9;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
  }
  .login-error-message {
    text-align: left;
    font-family: $newDefaultFont;
    font-weight: 300;
    font-size: 14px;
    align-items: center;
    color: #FF5151;

    margin-bottom: 40px;
    span {
      padding-left: 10px;
    }
  }
  .change-details-link {
    float: right;
  }
  .button_theme-default {
    background-color: #BC88E4;
  }
  .forgot-password {
    text-align: inherit !important;
  }
  .forgot-pass {
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #A7A7A7;
    cursor: pointer;
  }
  .text-login {
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #320959;
    margin-top: 86px;
    margin-bottom: 15px;
  }
  .login-content {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  .block-button {
    display: flex;
    justify-content: space-around;
    margin-top: 45px;
    align-items: center
  }
  .button-login {
    background-color: #7811C9;
    padding: 12px 61px 12px 61px;
    border-radius: 5px;
  }
  .logo-innerworks {
    padding-left: 25px;
    margin-top: 20px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }
  .logo-text {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $txtColorNewDesignMain;
    padding-left: 9px;
    padding-top: 5px;
  }
</style>
