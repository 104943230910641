import { render, staticRenderFns } from "./AccountNotFoundModal.vue?vue&type=template&id=28b1b467&scoped=true&"
import script from "./AccountNotFoundModal.vue?vue&type=script&lang=js&"
export * from "./AccountNotFoundModal.vue?vue&type=script&lang=js&"
import style0 from "./AccountNotFoundModal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./AccountNotFoundModal.vue?vue&type=style&index=1&id=28b1b467&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b1b467",
  null
  
)

export default component.exports