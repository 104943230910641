<template>
  <div class="new-bar-chart">
    <DetailsStrengthsModal
      v-if="detailsElement"
      :showing-modal="showModal"
      @show-modal="closeModal($event)"
      :strength-info="detailsElement"/>
    <AlertShowingResultsModal
      :switch-changed="switchChanged"
      :showing-modal="showAlertModal"
      @show-modal="closeAlertModal"/>
    <div class="strengths">
      <span class="font-weight-bold">Top 5 Strengths</span>
      <span v-if="!getProfile.token">
        Crowd
        <span class="font-weight-bold">
          {{respondentsCount}}
        </span>
      </span>
    </div>
    <div class="switch-results" v-if="getProfile.token && showSwitchButtons">
      <span class="font-weight-bold switch-result">
        <v-switch
          class="switch-you-result"
          color="#320959"
          v-model="youSwitch"
          inset
          :disabled="!crowdSwitch || !(respondentsCount >= 4)"
        >
          <template v-slot:label>
            <img :src="youIcon" alt="icon"/>
            <span class="input__label">You</span>
          </template>
        </v-switch>
      </span>
      <span class="font-weight-bold switch-result">
        <v-switch
          class="switch-crowd-result"
          color="#320959"
          v-model="crowdSwitch"
          inset
          :disabled="!youSwitch || !(respondentsCount >= 4)"
        >
          <template v-slot:label>
            <img :src="crowd" alt="people-icon"/>
            <span class="input__label">Crowd
              <div class="wrap-switch-text">of {{respondentsCount}}</div>
            </span>
          </template>
        </v-switch>
      </span>
    </div>
    <div
      v-for="(item, i) in strengthConversionScoreArray"
      :key="i">
        <div class="d-flex align-center bar-chart">
          <div class="questions-bar">
            <div class="circle">
              <span class="circle-text">{{ i + 1 }}</span>
            </div>
            <div class="question-bar-top">
              <div class="d-flex" @click="showExplainModal(item.name)">
                <span class="title-bar">
                  <img :src="personIcon" class="icon" alt="icon"/>
                  {{ item.name }}
                </span>
                <span class="percent percent-top">{{item.percentage !== 0 ?
                    `${item.percentage}%` : ''}}</span>
                <div class="px-2 fill-result inner-bar-top inner-bar"
                     :style="{width: `${item.percentage}%`,
                     'border-radius': item.percentage >= 100 ? '0' : ''}">
                </div>
              </div>
            </div>
            <div class="question-bar-bottom"
            >
              <div v-for="(item, j) in strengthConversionScoreForOtherArray"
                   :key="j">
                <div v-if="i === j" class="d-flex" @click="showExplainModal(item.nameForOther)">
                  <span class="title-bar">
                    <img :src="peopleIcon" class="icon" alt="icon"/>
                    {{item.nameForOther}}
                  </span>
                  <span class="percent percent-bottom">{{item.percentageForOther !== 0 ?
                    `${item.percentageForOther}%` : ''}}</span>
                  <div class="px-2 fill-result inner-bar-bottom inner-bar"
                       :style="{width: `${item.percentageForOther}%`,
                       'border-radius': item.percentageForOther >= 100 ? '0' : ''}">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import dumpArrayData from '@constants/strengths';
import peopleIcon from '@assets/people-icon.svg';
import youIcon from '@assets/you-icon.svg';
import personIcon from '@assets/person-icon.svg';
import crowd from '@assets/crowd.svg';
import DetailsStrengthsModal from '@components/Modals/DetailsStrengthsModal.vue';
import { mapGetters } from 'vuex';
import AlertShowingResultsModal from '@components/Modals/AlertShowingResultsModal.vue';

export default {
  name: 'NewBarChart',
  components: { AlertShowingResultsModal, DetailsStrengthsModal },
  props: {
    strengthConversionScoreObject: {
      type: Object,
      default() { return { }; },
    },
    strengthConversionScoreForOther: {
      type: Object,
      default() { return { }; },
    },
    respondentsCount: {
      type: Number,
    },
    showSwitchButtons: {
      type: Boolean,
      default() { return true; },
    },
  },
  data: () => ({
    activeItem: null,
    peopleIcon,
    personIcon,
    youIcon,
    crowd,
    showModal: false,
    detailsElement: null,
    youSwitch: true,
    crowdSwitch: true,
    showAlertModal: false,
    switchChanged: '',
  }),
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
    checkU2Results() {
      return typeof this.strengthConversionScoreForOtherArray[0] === 'string';
    },
    strengthConversionScoreArray() {
      const keys = Object.keys(this.strengthConversionScoreObject);

      if (keys.length === 0 || !this.youSwitch) {
        return ['', '', '', '', ''].map(() => ({
          nameForOther: '',
          percentage: 0,
        }));
      }

      return keys.map(key => ({
        name: key,
        percentage: Math.round(Number(this.strengthConversionScoreObject[key]
          / this.strengthConversionScoreObject[keys[0]] * 100)),
      }));
    },
    strengthConversionScoreForOtherArray() {
      const keys = Object.keys(this.strengthConversionScoreForOther);

      if (keys.length === 0 || !this.crowdSwitch || this.respondentsCount < 4) {
        return ['', '', '', '', ''].map(() => ({
          nameForOther: '',
          percentageForOther: 0,
        }));
      }

      return keys.map(key => ({
        nameForOther: key,
        percentageForOther: Math.round(Number(this.strengthConversionScoreForOther[key]
          / this.strengthConversionScoreForOther[keys[0]] * 100)),
      }));
    },
  },
  watch: {
    youSwitch(value) {
      if (value) {
        this.switchChanged = 'you';
      }
    },
    crowdSwitch(value) {
      if (value) {
        this.switchChanged = 'crowd';
      }
    },
  },
  methods: {
    getStrengthsContent(name) {
      const result = dumpArrayData.find(element => element.name === name);
      if (result) this.detailsElement = result;
      return undefined;
    },
    showExplainModal(item) {
      this.getStrengthsContent(item);
      this.showModal = true;
    },
    closeModal(value) {
      this.showModal = value;
      this.detailsElement = null;
    },
    closeAlertModal(value) {
      if (value.changeSwitchYou) {
        this.youSwitch = false;
      } else if (value.changeSwitchCrowd) {
        this.crowdSwitch = false;
      }
      this.showAlertModal = value.stateModal;
    },
  },
};
</script>

<style lang="scss" scoped>
  .questions-bar {
    width: 100%;
    border-radius: 0 20px 0 0;
    margin-left: 35px;
    position: relative;
  }
  .question-bar-top {
    width: 100%;
    background: #A7A7A7;
    border-radius: 0 50px 0 0;
    height: 34px;
    overflow: hidden;
    cursor: pointer;
  }
  .question-bar-bottom {
    width: 100%;
    background: #bebebf;
    height: 34px;
    border-radius: 0 0 50px 0;
    overflow: hidden;
    cursor: pointer;
  }
  .inner-bar-top {
    background-color: #320959;
  }
  .inner-bar {
    padding: 1px;
    border-radius: 0 20px 20px 0;
    height: 34px;
  }
  .inner-bar-bottom {
    background-color: #FF5151;
  }
  .inner-bar {
    border-radius: 0 20px 20px 0;
    height: 34px;
    padding: 1px;
  }
  .circle {
    width: 70px;
    height: 70px;
    background-color: white;
    border: 1px solid #320959;
    box-sizing: border-box;
    border-radius: 60px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -40px;
  }
  .circle-text {
    display: inline-block;
    font-family: $newDefaultFont;
    font-size: 28px;
    line-height: 32px;
    color: #320959;
  }
  @media (max-width: 400px) {
    .title-bar {
      font-size: 12px !important;
    }
    .percent {
      font-size: 12px !important;
    }
  }
  .title-bar {
    color: white;
    padding-left: 44px;
    position: absolute;
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
  }
  .icon {
    height: 16px;
    width: 16px;
    margin-right: 11px;
    margin-bottom: 3px;
  }
  .bar-chart {
    margin-bottom: 10px;
  }
  .strengths {
    display: flex;
    justify-content: space-between;
    color: #222222;
    font-family: $newDefaultFont;
    font-size: 14px;
    margin: 37px 0 18px 0;
  }
  .percent {
    right: 35px;
    position: absolute;
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }
  .percent-top {
    top: 10px;
  }
  .percent-bottom {
    bottom: 8px;
  }
  @media (max-width: 365px) {
    .percent {
      display: none;
    }
  }
  .switch-results {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  .input__label {
    margin-right: 10px;
    margin-left: 4px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
</style>

<style lang="scss">
  .switch-you-result .v-input__slot, .switch-crowd-result .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .switch-you-result, .switch-crowd-result {
    label {
      margin-bottom: 0 !important;
    }
    .v-input--switch__thumb {
      color: white !important;
    }
    .v-input--switch__track {
      opacity: 1 !important;
    }
  }
  .new-bar-chart {
    margin-left: 25px;
    margin-right: 25px;
  }
  .wrap-switch-text {
    padding-left: 5px;
  }
</style>
