<template>
  <v-dialog
    v-model="dialogConfirm"
    persistent
    max-width="500"
    content-class="dialog-delete-data"
  >
    <v-card>
      <v-card-title class="text-h5 dialog-delete-title">
        <img :src="completeIcon" alt="complete-icon"/>
        <v-spacer/>You data is now deleted.<v-spacer/>
      </v-card-title>
      <v-card-text class="modal-content-consequences">
        <div class="text-content-confirm">
          <span>Thanks for using innerworks and we hope to see you again soon</span>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="delete-data-buttons">
          <button
            @click="deleteUserAccount"
            class="back-to-login button-color">
            Back to Login
          </button>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import completeIcon from '@assets/complete.svg';

export default {
  name: 'DeleteUserSecondary',
  props: {
    dialogConfirm: {
      type: Boolean,
    },
  },
  data() {
    return {
      completeIcon,
    };
  },
  methods: {
    deleteUserAccount() {
      this.$router.push('main');
    },
  },
};
</script>

<style scoped>

</style>
