<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="480"
      content-class="dialog-alert-result"
    >
      <v-card>
        <v-card-title class="text-h5 dialog-alert-result-title">
          <v-spacer></v-spacer>
          Alert
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="dialog-alert-result-content">
          <div>
            You have show either your data and/or the Crowds' data
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="alert-result-modal-buttons">
            <button
              @click="closeModal"
              class="button-cancel-dialog button-color"
            >
              Okay
            </button>
            <button
              @click="confirmModal"
              class="button-comfirm-dialog button-color"
            >
              Cancel
            </button>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import arrowLeft from '@assets/arrow_left.svg';

export default {
  name: 'AlertShowingResultsModal',
  data: () => ({
    dialog: true,
    arrowLeft,
    test: [],
  }),
  props: {
    showingModal: {
      type: Boolean,
    },
    switchChanged: {
      type: String,
    },
  },
  methods: {
    closeModal() {
      this.$emit('show-modal', { stateModal: false, changeSwitch: true });
    },
    confirmModal() {
      if (this.switchChanged === 'you') {
        this.$emit('show-modal', {
          stateModal: false,
          changeSwitchYou: true,
          changeSwitchCrowd: false,
        });
      } else if (this.switchChanged === 'crowd') {
        this.$emit('show-modal', {
          stateModal: false,
          changeSwitchYou: false,
          changeSwitchCrowd: true,
        });
      } else {
        this.$emit('show-modal', true);
      }
    },
  },
  watch: {
    showingModal: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      },
    },
  },
};
</script>

<style lang="scss">
  .dialog-alert-result {
    margin: 0;
    overflow: hidden;
    font-family: $newDefaultFont;
    border-radius: 30px;
  }
  .dialog-alert-result-title {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #320959;
  }
  .dialog-alert-result-content {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
  }
  .dialog-sub-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #320959;
    text-align: center;
    margin-top: 75px;
  }
  .synonyms-item {
    padding: 5px 10px;
    background: #EAEBF1;
    border: 1px solid #EAEBF1;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
  .button-cancel-dialog {
    background: #7811C9;
    border: 1px solid #7811C9;
    border-radius: 5px;
    padding: 8px 55px 8px 55px;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
  .button-comfirm-dialog {
    color: #A7A7A7;
    font-weight: bold;
    font-size: 16px;
  }
  .button-cancel-dialog:focus {
    outline: 1px solid #888;
  }
  .button-comfirm-dialog:focus {
    outline: 1px solid #888;
  }
  .alert-result-modal-buttons {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    width: 100%;
    flex-direction: row;
    margin-bottom: 15px;
  }
</style>
