<template>
  <v-dialog
    v-model="dialog"
    width="480"
    content-class="custom-dialog-strengths-modal"
  >
    <v-card height="100vh" color="#F3F4F6">
      <v-btn
        class="arrow-to-back"
        text
        @click="dialog = false"
      >
        <img :src="arrowLeft"  alt="arrow-left"/>
      </v-btn>

      <v-card-text>
        <div class="dialog-title-info-modal">{{strengthInfo.name}}</div>
        <div class="dialog-content-info-modal"
             v-html="strengthInfo.content">{{strengthInfo.content}}</div>
<!--        <div class="dialog-sub-title">Synonyms</div>-->
<!--        <div class="dialog-synonyms">-->
<!--          <div v-for="item in symonumsData" :key="item" class="synonyms-item">{{item}}</div>-->
<!--        </div>-->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import arrowLeft from '@assets/arrow_left.svg';

export default {
  name: 'DetailsStrengthsModal',
  data: () => ({
    dialog: true,
    arrowLeft,
    symonumsData: [],
  }),
  props: {
    showingModal: {
      type: Boolean,
    },
    strengthInfo: {
      type: Object,
    },
  },
  watch: {
    showingModal: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      },
    },
    dialog(value) {
      this.$emit('show-modal', value);
    },
  },
};
</script>

<style lang="scss">
  .custom-dialog-strengths-modal {
    max-height: 100% !important;
    margin: 0;
    font-family: $newDefaultFont;
    z-index: 10;
    background-color: rgb(243, 244, 246);
    box-shadow: none;
    ol li{
      list-style-type: none;
      counter-increment: step-counter;
    }
    ol li:before {
      margin-left: -25px;
      margin-right: 5px;
      content: counter(step-counter) ")";
    }
  }
  @media (max-height: 660px) {
    .dialog-title-info-modal {
      margin-top: 25px !important;
    }
  }

  .arrow-to-back {
    margin-top: 10px !important;
    margin-left: 8px !important;
  }
  .dialog-title-info-modal {
    text-align: center;
    margin-top: 105px;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #320959;
  }
  .dialog-content-info-modal {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
    margin-top: 15px;
  }
  .dialog-sub-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #320959;
    text-align: center;
    margin-top: 75px;
  }
  .dialog-synonyms {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;
  }
  .synonyms-item {
    padding: 5px 10px;
    background: #EAEBF1;
    border: 1px solid #EAEBF1;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
</style>
