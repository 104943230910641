<template>
  <div>
    <div class="form-group form-group_submit">
      <span
        @click="openConsequencesWindowModalMethod"
        id="log_in"
      >
        <img :src="trash" alt="trash" class="trash-icon"/>Delete my Data
      </span>
    </div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="500"
        content-class="dialog-delete-data"
      >
        <v-card>
          <v-card-title class="text-h5 dialog-delete-title">
            <v-spacer></v-spacer>
            Delete my Data
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="modal-content-consequences">
            <div>
              You control your data so you will not be able to recover
              it later. All profile data will be lost and will need to be rebuilt from scratch.
            </div>
          </v-card-text>
          <v-card-actions>
            <div class="delete-data-buttons">
              <button
                @click="closeConfirmModal"
                class="button-cancel-dialog button-color"
              >
                Cancel
              </button>
              <button
                @click="openModalConfirm"
                class="button-comfirm-dialog button-color"
              >
                Continue
              </button>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import trash from '@assets/trash.svg';
import userBehave from '@constants/userBehave';

export default {
  name: 'DeleteUser',
  data() {
    return {
      trash,
      openConsequencesWindowModal: false,
      dialog: false,
      dialogConfirm: false,
    };
  },
  methods: {
    closeConfirmModal() {
      this.dialog = false;
    },
    openConsequencesWindowModalMethod() {
      this.$api.userBehaveSite.saveSiteEvent(userBehave.deleteAccountData);
      this.dialog = true;
    },
    async openModalConfirm() {
      this.dialog = false;
      const reason = '';

      try {
        await this.$api.auth.removeUserAccount(reason).then(() => {
          this.$emit('open-secondary-delete-modal', true);
          this.$store.dispatch('auth/logout');
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
  .trash-icon {
    padding-right: 5px;
    padding-bottom: 5px;
  }
  .button-color {
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .modal-content-consequences {
    color: #222222;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .text-privacy {
    padding-top: 30px;
  }
  .form-group_submit {
    margin-bottom: 30px;
  }
  .v-btn {
    padding: 0 16px;
  }
  .v-card__actions {
    justify-content: space-evenly;
  }
  .v-card__actions>.v-btn.v-btn {
    padding: 0 25px;
    border-radius: 15px;
  }
  .button-ok-confirm {
    padding: 0 100px !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
  }
  .text-privacy-link {
    color: #2d678f;
  }
  .text-content-confirm {
    text-align: center;
  }
  .dialog-delete-data {
    box-sizing: border-box;
    border-radius: 30px;
    font-family: $newDefaultFont;
  }
  .button-cancel-dialog {
    background: #7811C9;
    border: 1px solid #7811C9;
    border-radius: 5px;
    padding: 8px 55px 8px 55px;
  }
  .button-comfirm-dialog {
    color: #A7A7A7;
  }
  .v-card__text {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-bottom: 5px !important;
  }
  .dialog-delete-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #320959;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    img {
      padding-bottom: 15px;
    }
  }
  .back-to-login {
    font-size: 14px;
    line-height: 17px;
    color: #FF5151;
  }
  .delete-data-buttons {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
</style>
