<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="480"
      content-class="dialog-account-not-found"
    >
      <v-card>
        <v-card-title class="text-h5 dialog-account-not-found-title">
          <v-spacer></v-spacer>
          Account not Found
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="dialog-account-not-found-content">
          <div>
            We dont think you have set up an account.
            Click Continue to create an account or click Cancel to return to the previous screen
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="dialog-account-not-found-buttons">
            <button
              @click="closeModal"
              class="button-cancel-dialog button-color"
            >
              Cancel
            </button>
            <button
              @click="confirmModal"
              class="button-comfirm-dialog button-color"
            >
              Continue
            </button>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name: 'AccountNotFoundModal',
  data: () => ({
    dialog: true,
  }),
  props: {
    showingModal: {
      type: Boolean,
    },
  },
  methods: {
    closeModal() {
      this.$emit('show-modal', false);
    },
    confirmModal() {
      this.$router.push({
        name: 'registration',
      });
      this.$emit('show-modal', false);
    },
  },
  watch: {
    showingModal: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      },
    },
    dialog(value) {
      this.$emit('show-modal', value);
    },
  },
};
</script>
<style lang="scss">
  .dialog-account-not-found {
    border-radius: 30px;
  }
</style>

<style lang="scss" scoped>
  .dialog-account-not-found {
    max-height: 100% !important;
    margin: 0;
    overflow: hidden;
    font-family: $newDefaultFont;

  }
  .dialog-account-not-found-title {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #320959;
  }
  .dialog-account-not-found-content {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
    margin-top: 15px;
  }
  .dialog-account-not-found-buttons {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .button-cancel-dialog {
    background: #7811C9;
    border: 1px solid #7811C9;
    border-radius: 5px;
    padding: 8px 55px 8px 55px;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
  .button-comfirm-dialog {
    color: #A7A7A7;
    font-weight: bold;
    font-size: 16px;
  }
</style>
